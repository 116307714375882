import React from "react"

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-2 px-4">
      <div>© 2024 Aenduo S.r.l.</div>
    </footer>
  )
}

export default Footer
