import React from 'react';
import ReactDOM from 'react-dom';
import 'milligram/dist/milligram.min.css'
import App from './App';

import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";


ReactDOM.render(
<App />, document.getElementById('root'));