import React from "react"

// interface CardProps {
//   icon: string
//   title: string
//   description: string
//   link: string
// }

const Card = ({ icon, title, description, link }) => {
  return (
    <div
      className="card border-2"
      style={{
        borderColor: "aliceblue",
        transition: "transform 0.3s, box-shadow 0.3s",
      }}
      onMouseEnter={(e) => e.currentTarget.classList.add("shadow")}
      onMouseLeave={(e) => e.currentTarget.classList.remove("shadow")}>
      <div className="d-flex justify-content-center align-items-center bg-aliceblue p-4">
        <img
          src={icon}
          alt={`${title} Icon`}
          className="img-fluid"
          style={{ height: "150px", width: "auto" }}
        />
      </div>
      <div className="card-body">
        <h2 className="fs-5 text-primary mb-3 fw-semibold">{title}</h2>
        <p className="text-secondary fs-6 mb-4">{description}</p>
        <a
          href={link}
          className="btn btn-link p-0 text-decoration-none text-primary fw-bold">
          OPEN →
        </a>
      </div>
    </div>
  )
}

export default Card
