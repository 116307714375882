import React, {useEffect} from 'react'
import { signinRedirect } from '../services/userService'


function Login() {

  useEffect(() => {
    signinRedirect()
  }, []);

  return (
    <div></div>
  )
}

export default Login
