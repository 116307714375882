import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";

// interface HeaderProps {
//   userInfo: {
//     name: string;
//     initials: string;
//   };
// }

const Header = () => {
  const { t, i18n } = useTranslation();

  const user = useSelector(state => state.auth.user)

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const userInitials = user.profile.given_name ? user.profile.given_name.slice(0, 2).toUpperCase() : '';

  return (
    <header className="d-flex justify-content-between align-items-center px-4 py-2 border-bottom">
      <div className="d-flex align-items-center">
        <img
          src={`${process.env.PUBLIC_URL}/assets/PhilipsLogo.png`}
          alt="Logo"
          style={{ height: "60px" }}
        />
        <span className="ms-3 fs-4 fw-light">{t("header.portal")}</span>
      </div>
      <div className="d-flex align-items-center text-nowrap text-primary">
          <span>{user.profile.given_name}</span>
        <div className="dropdown ms-3">
          <button
            className="btn btn-outline-primary dropdown-toggle no-text-button"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ></button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <button
                className="dropdown-item"
                onClick={() => changeLanguage("en")}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/flags/eng.png`}
                  alt="English Flag"
                  style={{ width: "20px", marginRight: "5px" }}
                />
                ENG
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => changeLanguage("pl")}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/flags/pl.png`}
                  alt="Polish Flag"
                  style={{ width: "20px", marginRight: "5px" }}
                />
                PL
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button className="dropdown-item">
                <a className="text-black" href="https://ae-ph-ruka-app-is4-dev-we.aenduocloudservices.com/identity/Account/Manage">
                  <FontAwesomeIcon icon={faUser} className="me-2" /> {t("header.profile")}
                 
                </a>
              </button>
            </li>
            <li>
              <button className="dropdown-item">
                <a className="text-black" href="https://ae-ph-ruka-app-is4-dev-we.aenduocloudservices.com/Account/Logout">
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> {t("header.logout")}
                  
                </a>
              </button>
            </li>
          </ul>
        </div>
        {/* <span className="ms-3 fw-bold">{userInfo.initials}</span> */}
        <span className="ms-3 fw-bold">{userInitials}</span>
      </div>
    </header>
  );
};

export default Header;
