import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import enTranslations from "./translations/en/en.json"
import plTranslations from "./translations/pl/pl.json"

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    pl: {
      translation: plTranslations,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
