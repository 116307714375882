import { useTranslation } from "react-i18next"

export const useServices = () => {
  const { t } = useTranslation()

  return [
    {
      title: t("services.healthview.title"),
      description: t("services.healthview.description"),
      link: "https://ae-ph-ruka-app-hv-dev-we.aenduocloudservices.com",
      icon: "/assets/Healthview.png",
    },
    {
      title: t("services.cleverflow.title"),
      description: t("services.cleverflow.description"),
      // link: "https://ae-ph-ruka-app-cleverflow-dev-we.azurewebsites.net/",
      link: "https://ae-ph-ruka-app-cleverflow-dev-we.aenduocloudservices.com/",
      icon: "/assets/CleverFlow.png",
    },
    {
      title: t("services.powerprescription.title"),
      description: t("services.powerprescription.description"),
      link: "https://ae-ph-ruka-app-pp-dev-we.aenduocloudservices.com",
      icon: "/assets/PowerPrescription.png",
    },
    {
      title: t("services.businessinsight.title"),
      description: t("services.businessinsight.description"),
      link: "#",
      icon: "/assets/BusinessInsight.png",
    },
  ]
}
