import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useServices } from "../../data/services";
import Card from "../card/Card";



const Dashboard = () => {
  const { t } = useTranslation();
  const services = useServices();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1 p-5">
        <div className="my-3 text-primary fw-bold fs-1 display-4">{t("welcome")}</div>
        <p className="my-4 text-secondary fs-5">{t("description")}</p>
        <div className="row g-3">
          {services.map((service, index) => (
            <div className="col-md-6 col-lg-3" key={index}>
              <Card
                icon={service.icon}
                title={service.title}
                description={service.description}
                link={service.link}
              />
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Dashboard;
